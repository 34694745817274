export const dashboardFLData = { // The order in here is important for the order of the side nav!
    Prevention: {
        Services: {
            primary: "Prevention & Intervention",
            secondary: "Services",
            mapFilters: ['Provider Location '],
            chartFilters: ["Time", 'Location of Origin', 'Provider Location', "Organizations", "Ages", "Trafficking Types", "Victim Status"]
        },
        // Coordination: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Care Coordination",
        //     mapFilters: [],
        //     chartFilters: ["Time", "Location", "Trafficking Types", "Individuals Served"]
        // },
        // Screenings: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Screenings",
        //     mapFilters: ["Time", "Screenings", "Organizations"],
        //     chartFilters: ["Time", "Location", "Screenings", "Organizations", "Indicators", "Ages"]
        // }, 
    },
    Cases: {
        Federal: {
            primary: "Incidents & Cases",
            secondary: "Federal Cases",
            mapFilters: ["Year Case Filed", "Location ", "Trafficking Type"],
            chartFilters: ["Year Case Filed", "Location", "Trafficking Type"],
            defaultChartLoSelection: 'State',
            defaultChartLoValue: ["All states"],
            defaultStateValue: ['All states'],
            defaultDistrictValue: [],
            defaultCountyValue: [],
            // defaultChartLoSelection: 'Parish',
            // defaultChartLoValue: ["All parishes"],
            // defaultStateValue: ['Louisiana']
        },
        PublicSafety: {
            primary: "Incidents & Cases",
            secondary: "Law Enforcement",
            mapFilters: ["Arrest Year", "Offenses"],
            //mapFilters: ["Agency Type", "Offenses"],
            chartFilters: ["Arrest Year", "Location", "Offenses"]
        },
        StatePolicy: {
            primary: "Incidents & Cases",
            secondary: "State Policy",
            mapFilters: ['Policy', { name: 'Year', type: 'slider' }],
            chartFilters: ["Location", "Year", "Policy"],
            defaultChartLoSelection: 'State',
            defaultChartLoValue: ["All states"],
        },
        Demand: {
            primary: "Incidents & Cases",
            secondary: "Demand",
            mapFilters: ["Ad Sites"],
            chartFilters: ["Ad Sites"]
        }, 
        // Hotline: {
        //     primary: "Incidents & Cases",
        //     secondary: "Hotline",
        //     mapFilters: ["Time", "Situations"],
        //     chartFilters: ["Location", "Time", "Situations"]
        // },
        IMB: {
            primary: "Incidents & Cases",
            secondary: "Businesses of Interest",
            mapFilters: ["Year", "Location "],
            chartFilters: ["IMB Analysis", "Location"]
        }
    }
}