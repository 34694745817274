// import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsLAData = {
    mapZoom: 5,
    reportMapZoom: 4,
    mapBoxCenter: [-92.329102, 30.39183],
    mapBoxCenterReport: [-92.329102, 30.39183],
    mapMaxBounds: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    mapMaxBoundsReport: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    reportMapWidth: '577px',

    'Federal Cases': {
      mapZoom: mapsNationalData?.mapZoom,
      reportMapZoom: mapsNationalData?.reportMapZoom,
      mapBoxCenter: mapsNationalData?.mapBoxCenter,
      mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
      mapMaxBounds: mapsNationalData?.mapMaxBounds,
      mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
      reportMapWidth: mapsNationalData?.reportMapWidth,
      'Map View': mapsNationalData['Federal Cases']['Map View'],
      mapInfo: mapsNationalData['Federal Cases'].mapInfo,
    },
    'State Policy': {
      mapZoom: mapsNationalData?.mapZoom,
      reportMapZoom: mapsNationalData?.reportMapZoom,
      mapBoxCenter: mapsNationalData?.mapBoxCenter,
      mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
      mapMaxBounds: mapsNationalData?.mapMaxBounds,
      mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
      reportMapWidth: mapsNationalData?.reportMapWidth,
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}