import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import { availableDashboards } from '../../Data/availableDashboards'
import { only50StatesNames } from '../../Data/only50StatesNames'

import "./Dashboard.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppContext } from "../../Lib/UserContext";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Map from "./Map";
import Chart from "./Chart";
import FilterSummary from "./Filters/FilterSummary";
import Filter from "./Filters/Filter";
import Slider from "@material-ui/core/Slider";

const BlueSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#28ADE3",
    },
    "&$checked + $track": {
      backgroundColor: "#28ADE3",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  primaryButton: {
    backgroundColor: "#28ADE3",
    borderRadius: "3px",
    color: "white",
    marginTop: "20px",
  },
  appBar: {
    backgroundColor: "white",
    left: "72px",
    width: `calc(100% - 75px)`,
    zIndex: 888,
  },
  openIcon: {
    color: "#28ADE3",
  },
  toolbar: {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  content: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  container: {
    marginLeft: "72px",
    marginTop: "64px",
  },
  margin: {
    marginTop: "12px",
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const { stateLocation } = useAppContext();
  let dashboardData = availableDashboards.filter(d => d.stateLocation === stateLocation)[0].dashboardDataFile

  const defaultFilterArray = [
    {
      name: "Time",
      filter: "Services",
      selection: "Quarter & Fiscal Year",
      value: ["All quarters", "All years"],
      stateLocation: "TX",
    },
    {
      name: "Time ",
      filter: "Services",
      selection: "Fiscal Year",
      value: ["All years"],
      stateLocation: "TX",
    },
    {
      name: "Location ",
      filter: "Services",
      selection: "State",
      value: ["State"],
      stateLocation: "TX",
    },
    {
      name: "Location",
      filter: "Services",
      selection: "County",
      value: ["All counties"],
      stateLocation: "TX",
    },
    {
      name: "Provider Location ",
      filter: "Services",
      selection: "State",
      value: ["State"],
      stateLocation: "LA",
    },
    {
      name: "Location of Origin",
      filter: "Services",
      selection: "Parish",
      value: ["All parishes"],
      stateLocation: "LA",
    },
    {
      name: "Provider Location",
      filter: "Services",
      selection: "Parish",
      value: ["All parishes"],
      stateLocation: "LA",
    },
    {
      name: "Ages",
      filter: "Services",
      value: ["All ages"],
      stateLocation: "LA",
    },
    {
      name: "Organizations",
      filter: "Services",
      value: ["All organizations"],
      stateLocation: "LA",
    },
    {
      name: "Trafficking Types",
      filter: "Services",
      value: ["All types"],
      stateLocation: "LA",
    },
    {
      name: "Time",
      filter: "Services",
      value: ["All years"],
      stateLocation: "LA",
    },
    {
      name: "Victim Status",
      filter: "Services",
      value: ["Confirmed", "Suspected"],
      stateLocation: "LA",
    },
    {
      name: "Time",
      filter: "Locations",
      value: ["All years"],
    },
    {
      name: "Location Type",
      filter: "Locations",
      value: ["Parish of Trafficking"],
    },
    {
      name: "Location",
      filter: "Locations",
      selection: "Parish",
      value: ["All parishes"],
    },
    {
      name: "Ages",
      filter: "Locations",
      value: ["All ages"],
    },
    {
      name: "Organizations",
      filter: "Locations",
      value: ["All organizations"],
    },
    {
      name: "Trafficking Types",
      filter: "Locations",
      value: ["All types"],
    },
    {
      name: "Victim Status",
      filter: "Locations",
      value: ["Confirmed", "Suspected"],
      stateLocation: "LA",
    },
    {
      name: "Time",
      filter: "Care Coordination",
      selection: "Quarter & Fiscal Year",
      value: ["All quarters", "All years"],
    },
    {
      name: "Location",
      filter: "Care Coordination",
      stateLocation: stateLocation,
      selection: stateLocation === "TX" ? "County" : "Region",
      value: stateLocation === "TX" ? ["All counties"] : ['All regions'],
    },
    {
      name: "Trafficking Types",
      filter: "Care Coordination",
      stateLocation: stateLocation,
      value: ["All types"],
    },
    {
      name: "Individuals Served",
      filter: "Care Coordination",
      stateLocation: stateLocation,
      value: ["Trafficking Victims"],
    },
    {
      name: "Time",
      filter: "Screenings",
      selection: "All time",
      value: ["All time"],
    },
    {
      name: "Location",
      filter: "Screenings",
      selection: "County",
      value: stateLocation === "TX" ? ["All counties"] : ["All parishes"],
    },
    {
      name: "Organizations",
      filter: "Screenings",
      value: ["All organizations"],
    },
    {
      name: "Screenings",
      filter: "Screenings",
      value: ["All screenings"],
    },
    {
      name: "Ages",
      filter: "Screenings",
      value: ["All ages"],
    },
    {
      name: "Indicators",
      filter: "Screenings",
      value: ["Clear Concern"],
    },
    // {
    //   name: "Agency Type",
    //   filter: "Law Enforcement",
    //   value: ["Arresting Agency (ORIA)"],
    // },
    {
      name: "Offenses",
      filter: "Law Enforcement",
      value: ["All offenses"],     
      stateLocation: stateLocation,
    },
    {
      name: 'Year',
      filter: 'State Policy',
      value: ['2023']
    },
    {
      name: 'Policy',
      filter: 'State Policy',
      value: ['All policies']
    },
    {
      name: 'Location',
      filter: 'State Policy',
      value: ["All States"]
    },
    {
      name: "Arrest Year",
      filter: "Law Enforcement",
      value: ['All years'],
      stateLocation: stateLocation,
    },
    {
      name: "Location",
      filter: "Law Enforcement",
      stateLocation: stateLocation,
      selection: "County",
      value: stateLocation === 'TX' ? ["All counties"] : stateLocation === 'LA' ? ["All parishes"] : ["All counties"] 
    },
    {
      name: 'Map View',
      filter: 'Federal Cases',
      stateLocation: stateLocation,
      value: ['State Prosecuted'],
      stateClickedData: null
    }, 
    {
      name: 'Year Case Filed',
      filter: 'Federal Cases',
      value: ["All Years"]
    },
    {
      name: 'Location ',
      filter: 'Federal Cases',
      stateLocation: stateLocation,
      value: ['All States'],
    }, 
    {
      name: 'Location',
      filter: 'Federal Cases',
      stateLocation: stateLocation,
      selection: dashboardData.Cases.Federal.defaultChartLoSelection,
      value: dashboardData.Cases.Federal.defaultChartLoValue,
      stateValue: dashboardData.Cases.Federal?.defaultStateValue,
      districtValue: dashboardData.Cases.Federal?.defaultDistrictValue,
      countyValue: dashboardData.Cases.Federal?.defaultCountyValue
    },
    {
      name: "Trafficking Type",
      filter: "Federal Cases",
      value: ["All types"],
    },
    {
      name: "Location",
      filter: "Texas Department of Criminal Justice",
      selection: "County",
      value: ["All counties"],
    },
    {
      name: "Offenses",
      filter: "Texas Department of Criminal Justice",
      value: ["All offenses"],
    },
    {
      name: "Ad Sites",
      filter: "Demand",
      value: ["All Sites"],
    },
    {
      name: "Location",
      filter: "Businesses of Interest",
      selection: "County",
      stateLocation: stateLocation,
      value: stateLocation === "LA" ? ["All parishes"] : ["All counties"],
    },
    {
      name: "IMB Analysis",
      stateLocation: stateLocation,
      filter: "Businesses of Interest",
      value: ["All Businesses"],
    },
    {
      name: "Year",
      filter: "Businesses of Interest",
      value: ["2024"],
    },
    {
      name: "Location ",
      filter: "Businesses of Interest",
      value: stateLocation === "LA" ? ["Parish"] : ["County"],
    },
    {
      name: "Location",
      filter: "Hotline",
      selection: "County",
      value: stateLocation === "LA" ? ["All parishes"] : ["All counties"], // backend has lowercase 'counties'
    },
    {
      name: "Situations",
      filter: "Hotline",
      selection: "All Cases",
      value: ["Involves Minors", "Involves Adults"],
    },
    {
      name: "Time",
      filter: "Hotline",
      selection: "Quarter & Fiscal Year", // Fiscal on the backend, Calendar year on the frontend
      value: ["All years"],
    },
    // },{
    //   name: 'Location',
    //   filter: 'News',
    //   value: ["State"]
    // }
  ];

  const [loading, setLoading] = React.useState(false);
  const [chartLoading, setChartLoading] = React.useState(false);
  const [primaryNav, setPrimaryNav] = React.useState([]);
  const [secondaryNav, setSecondaryNav] = React.useState([]);

  const [servicesData, setServicesData] = React.useState({});
  const [careData, setCareData] = React.useState([]);
  const [screeningsData, setScreeningsData] = React.useState([]);
  const [trafLocationsData, setTrafLocationsData] = React.useState([]);
  const [publicSafetyData, setPublicSafetyData] = React.useState([]);
  const [federalCasesData, setFederalCasesData] = React.useState([]);
  const [statePolicyData, setStatePolicyData] = React.useState([]);
  const [tdcjData, setTDCJData] = React.useState([]);
  const [demandData, setDemandData] = React.useState([]);
  const [newsData, setNewsData] = React.useState([]);
  const [hotlineData, setHotlineData] = React.useState([]);
  const [imbData, setIMBData] = React.useState([]);
  const [communityData, setCommunityData] = React.useState([]);
  const [mapData, setMapData] = React.useState([]);
  const [mapFilters, setMapFilters] = React.useState([]);
  const [chartFilters, setChartFilters] = React.useState([]);
  const [selectedFilters, setSelectedFilters] =
    React.useState(defaultFilterArray);
  const [switchToggle, setSwitchToggle] = React.useState(true);
  const [statePolicyYear, setStatePolicyYear] = useState(2023)
  const [statePolicyStateSelection, setStatePolicyStateSelection] = useState([
    { name: 'U.S.', stateLocation: 'U.S.' },
    { name: stateLocation === 'National' ? 'Texas' : only50StatesNames.filter(s => s.stateLocation === stateLocation)[0].name, stateLocation: stateLocation === 'National' ? 'TX' : stateLocation}
  ])

  // useEffect(() => {
  //   //Check if filters in local storage
  //   if (localStorage.getItem("filters")) {
  //     let storedRole = JSON.parse(localStorage.getItem("filters"));
  //     setSelectedFilters(storedRole)
  //   }
  //    //Check if mapChartToggle in local storage
  //    if (localStorage.getItem("mapChartToggle")) {
  //     let storedToggle = JSON.parse(localStorage.getItem("mapChartToggle"));
  //     if (storedToggle){
  //       setSwitchToggle(true)
  //     } else {
  //       setSwitchToggle(false)
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   setSwitchToggle(true)
  // }, [props.primaryNav, props.secondaryNav])

  const handleSourceClose = () => {
    setLoading(false);
  };

  const handleChartLoadingClose = () => {
    setChartLoading(false);
  };

  // Refs for Filters
  const elRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Opening Filters
  const handleFilterClick = (e, filter) => {
    if (e.currentTarget.name === open) {
      setOpen(null);
      setAnchorEl(null);
    } else {
      setOpen(filter);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  // Filter Callback
  const handleFilterCallback = (item) => {
    var newArray = [...selectedFilters];
    for (let i = 0; i < newArray.length; i++) {
      if (
        newArray[i].name === item.name &&
        newArray[i].filter === item.filter
      ) {
        newArray[i] = item
      }
    }
    setSelectedFilters(newArray);
    // localStorage.setItem("filters", JSON.stringify(newArray));
  };

  const handleUpdateSummaryFilterItem = (item) => {
    setOpen(false)
    setSelectedFilters(item);
    setStatePolicyYear(2023)
    //localStorage.setItem("filters", JSON.stringify(item));
  };

  // Map to Charts
  const handleSwitchChange = () => {
    setSwitchToggle(!switchToggle);
    //localStorage.setItem("mapChartToggle", JSON.stringify(!switchToggle));
  };

  const selectMap = () => {
    setSwitchToggle(true);
  };

  const selectChart = () => {
    setSwitchToggle(false);
  };

  // Add to Reports
  const addToReportPage = async (data, type, chartLine, chartLineTwo, activeCategory) => {
    // Instead of sending all selectedFilters, get filters based on if pressed from map or chart
    // Only add to the report filter previews the current filters on the page in the nav, not all filters for 'type'
    let filterConfigsOnPage = [];

    const getFilterConfigOnPage = (filtersOnPage) => {
      for (let i = 0; i < selectedFilters.length; i++) {
        if (
          selectedFilters[i].stateLocation === stateLocation ||
          !selectedFilters[i].stateLocation
        ) {
          for (let k = 0; k < filtersOnPage.length; k++) {
            if (
              selectedFilters[i].filter === type &&
              selectedFilters[i].name === (filtersOnPage[k]?.name ?? filtersOnPage[k])
            ) {
              filterConfigsOnPage.push(selectedFilters[i]);
            }
          }
        }
      }
    };
    if (switchToggle) {
      // map is in view, use mapFilters
      if (mapFilters) {
        getFilterConfigOnPage(mapFilters);
      }
    } else {
      // charts are in view, use chartFilters
      if (chartFilters) {
        getFilterConfigOnPage(chartFilters);
      }
    }
    if (filterConfigsOnPage) {
      props.addToReportPage(data, filterConfigsOnPage, type, chartLine, chartLineTwo, activeCategory);
    } else {
      props.addToReportPage(data, selectedFilters, type, chartLine, chartLineTwo, activeCategory);
    }
  };
  // console.log(stateLocation);
  useEffect(() => {
    async function getNav() {
      var stateLo = await stateLocation
      var stateData = availableDashboards.filter(d => d.stateLocation === stateLo)[0].dashboardDataFile
      //Set Map vs Chart

      // console.log('stateData ', stateData);
      if (stateData[props.primaryNav]?.[props.secondaryNav]?.secondary !== secondaryNav) {
        setSwitchToggle(true);
      }
      // Set Navigation
      if (
        props.primaryNav !== "help" &&
        props.primaryNav !== "admin" &&
        props.primaryNav !== "reports"
      ) {
        if (stateData[props.primaryNav]?.[props.secondaryNav]) {
          setPrimaryNav(stateData[props.primaryNav][props.secondaryNav].primary);
          setSecondaryNav(stateData[props.primaryNav][props.secondaryNav].secondary);
          for (let i = 0; i < selectedFilters.length; i++) {
            if (props.secondaryNav === "IMB" && selectedFilters[i].name === 'Location ' && selectedFilters[i].filter === 'Businesses of Interest' && selectedFilters[i].value[0] === 'Zip Code') { // Remove the year filter for IMB in zipcode map view
              return setMapFilters(stateData[props.primaryNav][props.secondaryNav].mapFilters.filter(x => x !== 'Year'));
            } else {
              setMapFilters(stateData[props.primaryNav][props.secondaryNav].mapFilters);
            }
          }
          setChartFilters(stateData[props.primaryNav][props.secondaryNav].chartFilters);
        }
        // else {
        //   props.setPrimaryNav('Prevention')
        //   props.setSecondaryNav('Services')
        //   props.setActivePrimaryNav('Prevention')
        //   props.setActiveSecondaryNav('Services')
        // }
      }

      //setOpen(false)
      //setSelectedFilters(defaultFilterArray)

      // Get Data
      if (
        props.primaryNav === "Prevention" &&
        props.secondaryNav === "Services" &&
        stateLocation === "TX"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getservicesmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getservicesdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setServicesData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Prevention" &&
        props.secondaryNav === "Services" &&
        stateLocation === "LA"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getservicesmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log("getservicesmapdata, selectedFilters: ", response, selectedFilters);
          setMapData(response);
          setLoading(false);
        });

        API.post("lighthouse-data-initiative", "getservicesladata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          setServicesData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Prevention" &&
        props.secondaryNav === "Coordination" && stateLocation === 'TX'
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getcaremapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          setMapData(response);
          setLoading(false);
        });

        API.post("lighthouse-data-initiative", "getcaredata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setCareData(response);
          setChartLoading(false);
        });
      } else if (props.primaryNav === "Prevention" &&
        props.secondaryNav === "Coordination" && stateLocation === 'LA') {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getcaremapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          setMapData(response);
          setLoading(false);
        });

        API.post("lighthouse-data-initiative", "getlacaredata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setCareData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Prevention" &&
        props.secondaryNav === "Screenings"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getscreeningmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response);
          setMapData(response);
          setLoading(false);
        });

        API.post("lighthouse-data-initiative", "getscreeningdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          setScreeningsData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Prevention" &&
        props.secondaryNav === "TraffickingLocations"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "gettraflocationsmapdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "gettraflocationsdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setTrafLocationsData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "Federal"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getfederalcasesmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log('federal: ', response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getfederalcasesdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log("for federal data: ", response);
          setFederalCasesData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "StatePolicy"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getstatepolicymapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getstatepolicydata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
            states: statePolicyStateSelection
          },
        }).then((response) => {
          // console.log("for statepolicy data: ", response);
          setStatePolicyData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "PublicSafety"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getpublicsafetymapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response)
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getpublicsafetydata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response);
          setPublicSafetyData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "TDCJ"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "gettdcjmapdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "gettdcjdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setTDCJData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "Demand"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getdemandmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getdemanddata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log(response);
          setDemandData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "News"
      ) {
        // setLoading(true)
        setChartLoading(true);
        // API.post("lighthouse-data-initiative", "getnewsmapdata", {
        //   body: {
        //     filters: selectedFilters,
        //   },
        // }).then(response => {
        //   setMapData(response)
        //   setLoading(false)
        // })
        API.post("lighthouse-data-initiative", "getnewsdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);
          setNewsData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Cases" &&
        props.secondaryNav === "Hotline"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "gethotlinemapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log('gethotlinemapdata: ', response);
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "gethotlinedata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation,
          },
        }).then((response) => {
          // console.log('for hotline data', response)
          setHotlineData(response);
          setChartLoading(false);
        });
      } else if (props.primaryNav === "Cases" && props.secondaryNav === "IMB") {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getimbmapdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation
          },
        }).then((response) => {
          // console.log(response, 'map repsonse')
          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getimbdata", {
          body: {
            filters: selectedFilters,
            stateLocation: stateLocation
          },
        }).then((response) => {
          // console.log(response);
          setIMBData(response);
          setChartLoading(false);
        });
      } else if (
        props.primaryNav === "Risk" &&
        props.secondaryNav === "Community"
      ) {
        setLoading(true);
        setChartLoading(true);
        API.post("lighthouse-data-initiative", "getcommunityriskmapdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log(response);

          setMapData(response);
          setLoading(false);
        });
        API.post("lighthouse-data-initiative", "getcommunityriskdata", {
          body: {
            filters: selectedFilters,
          },
        }).then((response) => {
          // console.log('getcommunityriskdata', response);
          setCommunityData(response);
          setChartLoading(false);
        });
      }
    }

    if (stateLocation && stateLocation !== '') {
      getNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.primaryNav, props.secondaryNav, selectedFilters, stateLocation]);


  const onStatePolicyYear = (e) => {
    handleFilterCallback({
      name: 'Year',
      filter: 'State Policy',
      value: [`${statePolicyYear}`],
    })
  }

  const onStatePolicyStateSelection = (indexToUpdate, newValue, beArray) => {
    // console.log(e, "DASHBOARD")
    setStatePolicyStateSelection(prevItems => prevItems.map((item, i) =>
      i === indexToUpdate ? newValue : item
    ));
    API.post("lighthouse-data-initiative", "getstatepolicydata", {
      body: {
        filters: selectedFilters,
        stateLocation: stateLocation,
        states: beArray
      },
    }).then((response) => {
      // console.log("for statepolicy data: ", response);
      setStatePolicyData(response);
      setChartLoading(false);
    });
  }

  return (
    <div className='flex' id="dashboard">
      <CssBaseline />
      <div className={`${classes.content} contentDiv`}>
        <AppBar color="default" position="fixed" className={`${classes.appBar} appBarHeader`}>
          <Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" noWrap className='dashboard-title fontWeight700'>
                  {`${primaryNav} > ${secondaryNav}`}
                </Typography>
                <Divider orientation="vertical" flexItem />

                {/* Filter Summary */}
                <Popper
                  open={open === "main"}
                  anchorEl={anchorEl}
                  placement={"bottom-start"}
                  transition
                  className='popper-main'
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <FilterSummary
                          filters={switchToggle ? mapFilters : chartFilters}
                          selectedFilters={selectedFilters}
                          handleUpdateSummaryFilterItem={
                            handleUpdateSummaryFilterItem
                          }
                          data={secondaryNav}
                          secondaryNav={props?.secondaryNav}
                        />
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <Button
                  ref={elRef}
                  onClick={(e) => handleFilterClick(e, "main")}
                  className="filterButton"
                  name="main"
                >
                  <FilterListIcon />
                </Button>

                {/* All Filter */}
                {switchToggle
                  ? mapFilters?.map((filter, key) => {
                    return (
                      <div key={key}>
                        {!filter.type && (
                          <>
                            <Popper
                              open={open === filter}
                              anchorEl={anchorEl}
                              placement={"bottom-start"}
                              transition
                              className="popper"
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={50}>
                                  <Paper className='borderRadius8'>
                                    <Filter
                                      filter={filter}
                                      filterCallback={handleFilterCallback}
                                      selectedFilters={selectedFilters}
                                      handleClose={handleClose}
                                      data={secondaryNav}
                                    />
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                            <Button
                              ref={elRef}
                              onClick={(e) => handleFilterClick(e, filter)}
                              className="filterButton"
                              name={filter}
                            >
                              {filter} <ArrowDropDownIcon />
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  })
                  : chartFilters?.map((filter, key) => {
                    return (
                      <div key={key}>
                        <Popper
                          open={open === filter}
                          anchorEl={anchorEl}
                          placement={"bottom-start"}
                          transition
                          className="popper"
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={50}>
                              <Paper className='borderRadius8'>
                                <Filter
                                  filter={filter}
                                  filterCallback={handleFilterCallback}
                                  selectedFilters={selectedFilters}
                                  handleClose={handleClose}
                                  data={secondaryNav}
                                />
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                        <Button
                          ref={elRef}
                          onClick={(e) => handleFilterClick(e, filter)}
                          className="filterButton"
                          name={filter}
                        >
                          {filter} <ArrowDropDownIcon />
                        </Button>
                      </div>
                    );
                  })}
              </div>

              <div className='flexRowCenter'>
                <>
                {switchToggle && secondaryNav === 'State Policy' &&
                  <div style={{ marginRight: 50 }}>
                  <p className='spYearText'>{statePolicyYear}</p>
                  <Slider
                    className='spYearSlider'
                      aria-label="year"
                    value={statePolicyYear}
                    onChange={(e, newValue) => setStatePolicyYear(newValue)}
                    onChangeCommitted={onStatePolicyYear}
                      // valueLabelDisplay="auto"
                      step={1}
                      min={2003}
                      max={2023} />
                  </div>
                  }
                  </>
              
                {/* federal cases extra nav btn when a state location > state is clicked */}
                <>
                {switchToggle && mapData?.stateClickedData &&
                  <div style={{marginRight: 15}}>
                  <button className='btn pointer' onClick={() => handleFilterCallback({
                    name: 'Map View',
                    filter: 'Federal Cases',
                    stateLocation: stateLocation,
                    value: ['State Prosecuted'],
                    stateClickedData: null
                  })}>
                      [ x ] National View
                    </button>
                  </div>
                  }
                  </>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  className={`pointer ${switchToggle ? 'fontWeight700' : 'fontWeight500'}`}
                  onClick={selectMap}
                >
                  Map
                </p>
                <BlueSwitch
                  checked={!switchToggle}
                  color="primary"
                  onChange={handleSwitchChange}
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p
                    className={`pointer ${switchToggle ? 'fontWeight500' : 'fontWeight700'}`}
                  onClick={selectChart}
                >
                  Charts
                </p>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.container}>
          {switchToggle ? (
            <div>
              <Map
                charts={secondaryNav}
                data={mapData}
                filters={selectedFilters}
                addToReportPage={addToReportPage}
                filterCallback={handleFilterCallback}
              />
            </div>
          ) : (
              <div>
                <Chart
                  charts={secondaryNav}
                  services={servicesData}
                  care={careData}
                  screenings={screeningsData}
                  trafLocations={trafLocationsData}
                  publicSafety={publicSafetyData}
                  federalCases={federalCasesData}
                  statePolicy={statePolicyData}
                  tdcj={tdcjData}
                  demand={demandData}
                  hotline={hotlineData}
                  news={newsData}
                  imb={imbData}
                  community={communityData}
                  addToReportPage={addToReportPage}
                  chartLoading={chartLoading}
                  handleChartLoadingClose={handleChartLoadingClose}
                  bannerOpen={props.bannerOpen}
                  handleStateClick={onStatePolicyStateSelection}
                  statePolicyStateSelection = {statePolicyStateSelection}
                />
              </div>
            )}
        </div>
      </div>
      <Dialog
        open={loading}
        onClose={handleSourceClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Updating Data...</h2>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
}
