// import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsFLData = {
    mapZoom: 2,
    reportMapZoom: 4,
    mapBoxCenter: [ -81.760254, 27.994402],
    mapBoxCenterReport: [ -81.760254, 27.994402],
    mapMaxBounds: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
    mapMaxBoundsReport: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
    reportMapWidth: '577px',

    'Federal Cases': {
      mapZoom: mapsNationalData.mapZoom,
      reportMapZoom: mapsNationalData.reportMapZoom,
      mapBoxCenter: mapsNationalData.mapBoxCenter,
      mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
      mapMaxBounds: mapsNationalData.mapMaxBounds,
      mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
      reportMapWidth: mapsNationalData.reportMapWidth,
      'Map View': mapsNationalData['Federal Cases']['Map View'],
      mapInfo: mapsNationalData['Federal Cases'].mapInfo,
    },
    'State Policy': {
      mapZoom: mapsNationalData.mapZoom,
      reportMapZoom: mapsNationalData.reportMapZoom,
      mapBoxCenter: mapsNationalData.mapBoxCenter,
      mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
      mapMaxBounds: mapsNationalData.mapMaxBounds,
      mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
      reportMapWidth: mapsNationalData.reportMapWidth,
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}