import React, { useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
import { Checkbox } from '../../../Components/Checkbox'

export default function GridChart(props) {
  const { chartColorArray } = useAppContext()
    const anchor = React.createRef();

    //Only for graphs with legends
   // const [keys, setKeys] = useState([]);
    //const [selectedKeys, setSelectedKeys] = useState(props.legendCategories ? props.legendCategories : []);


    // const handleLegendClick = (e, key) => {
    //   if (selectedKeys.includes(key)) {
    //     setSelectedKeys(selectedKeys.filter((_key) => _key !== key));
    //   } else {
    //     var newArray = Array.from(new Set([...selectedKeys, key]));
    //     setSelectedKeys(newArray);
    //   }
    // };
  
    
  useEffect(() => {
    var margin = { top: 20, right: 80, bottom: 90, left: 80 };
    let paddingTotal = 40
    var width = 900;
    var height = 750;

    if (props.report) {
      width = 1000;
    }
    // eslint-disable-next-line no-unused-vars
    const colors = props?.colors ?
      d3.scaleOrdinal(props.colors)
        :
      d3.scaleOrdinal(chartColorArray);

    var xmax = 0;
    var ymax = 0;
    if (props.data){
      var data = props.data;

      //Clickable Legend
      // if (props.legendCategories) {
      //   setKeys(props.legendCategories)
      //   data = data.filter(item => selectedKeys.includes(item.type))
      // }

      xmax = d3.max(data, (d) => +d[props.xaxisValue]) + 1;
      ymax = d3.max(data, (d) => +d[props.yaxisValue]);

      if (xmax < 5) {
        xmax = 5
      }

      if (ymax < 10) {
        ymax = 10
      }

      let medianXAxis = d3.mean(data, (d) => +d[props.xaxisValue]);
      let medianYAxis = d3.mean(data, (d) => +d[props.yaxisValue]);

  
      const svg = d3.select(anchor.current);
  
      svg.selectAll("svg").remove();
      svg.selectAll(".tooltip").remove();
  
      var barsvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(0, 0)`);
  
      var tooltip = d3
        .select(anchor.current)
        .append("div")
        .attr("class", "tooltip")
          .style("opacity", 0)
        .style("top", 0)
        .style("left", 0)
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          // .style("z-index", "-1")
          .style("padding", "5px")
        .style("width", "200px");
      tooltip.append("div").append("strong").attr("class", "label");
      tooltip.append("div").attr("class", "xaxis");
      tooltip.append("div").attr("class", "yaxis");
  
      var x = d3
        .scaleLinear()
        .domain([0, xmax])
        .range([margin.left + (paddingTotal / 2), (width - margin.right) ])
        .nice();
  
      var y = d3
        .scaleLinear()
        //.domain(d3.range(data.length))
        .domain([0, ymax])
        .range([(height - margin.bottom) - paddingTotal, margin.top + (paddingTotal / 2)])

        var xAxisFormat;
        if (xmax < 20) {
          xAxisFormat = d3.axisBottom(x).ticks(xmax);
        } else {
          xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);
        }
  
      var xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(xAxisFormat);
        
      //var yAxis = d3.axisLeft(y).ticks(10);
      var yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .call(d3.axisLeft(y).ticks(null, data.y))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );

        //Horizontal Mean Line
        barsvg.append("line")
        .attr("x1", margin.left)
        .attr("x2", width - margin.right)
        .attr("y1", y(medianYAxis))
        .attr("y2", y(medianYAxis))
          .attr("stroke", chartColorArray[0])
        .attr("stroke-dasharray", "4")
        .attr("stroke-width", 2)
  
  
        //Vertical Mean Line
         barsvg.append("line")
         .attr("x1", x(medianXAxis))
         .attr("x2", x(medianXAxis))
           .attr("y1", paddingTotal/2)
           .attr("y2", (height - 80) - paddingTotal)
           .attr("stroke", chartColorArray[1])
         .attr("stroke-dasharray", "4")
         .attr("stroke-width", 2);
      
      
      let markers = barsvg.selectAll("g")
        .data(data)
        .enter().append("g")
        .attr("transform", (d) => `translate(${x(d[props.xaxisValue]) - 15}, ${y(d[props.yaxisValue]) - 15})`)
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove);

      markers.append("rect")
        .attr("width", 30)
        .attr("height", 30)
        .attr('class', 'noHoverOpacity')
        .attr("fill", "white") // 'white'
        .style("stroke", "black")
        .attr("rx", '7px')
        .attr("ry", '7px')
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove);

      markers.append("text")
        .text((d) => {
          let otherItems = data.filter(obj => obj[props.xaxisValue] === d[props.xaxisValue] && obj[props.yaxisValue] === d[props.yaxisValue])
          // console.log(otherItems)
          if (otherItems.length > 1) {
            return ">1"
          } else {
            return d.label
          }
        })
        .attr("width", 20)
        .attr("x", 15)
        .attr("y", 16)
        .attr("text-anchor", "middle")  // Center text horizontally at the x position
        .attr("dominant-baseline", "middle")  // Vertically center the text;
        .attr('cursor', 'default')
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove);
        
         
      barsvg.append("g").call(xAxis);
  
      barsvg.append("g").call(yAxis);

  
      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")     
        let otherItems = data.filter(obj => obj[props.xaxisValue] ===  d[props.xaxisValue] && obj[props.yaxisValue] ===  d[props.yaxisValue])
        if (otherItems.length === 2) {
          let labelValue = '';
          for (let i = 0; i < otherItems.length; i++){
            if (i === otherItems.length - 1) {
              labelValue += otherItems[i].name
            } else {
              labelValue += otherItems[i].name + " & "
            }
          }
          tooltip.select(`.label`).text(labelValue).style("font-weight", "900");
        } else if (otherItems.length > 2) {
          let labelValue = '';
          for (let i = 0; i < otherItems.length; i++){
            if (i === otherItems.length - 1) {
              labelValue += otherItems[i].name
            } else {
              labelValue += otherItems[i].name + ", "
            }
          }
          tooltip.select(`.label`).text(labelValue).style("font-weight", "900");
        } else {
          tooltip.select(".label").text(d.name);
        }    
        tooltip.select(".xaxis").text("Average Lag Time: " + d[props.xaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        tooltip.select(".yaxis").text("Number of Policies: " + d[props.yaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      }
  
      function onMouseOut() {
        tooltip.style("opacity", 0);
        // tooltip.style("z-index", "-1")
  
      }
  
      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        tooltip
          .style("top", ((d3.event.clientY - tooltip.node().getBoundingClientRect().height) - 30) + "px")
          .style("left", (d3.event.clientX - 100) + "px")
      }
  
      // text label for the x axis
      var xaxisLocation = height - 30

      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr(
          "transform",
          "translate(" + ((width - margin.right) / 2) + " ," + xaxisLocation + ")"
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);

  
      // text label for the y axis
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr("transform", "rotate(-90)")
          .attr("y", 20)
          .attr("x", 0 - height / 3)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(props.yaxis);


        // Quadrant 1
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr(
            "transform",
            "translate(" + ((width - margin.right) / 3) + " ," + (xaxisLocation - 70) + ")"
          )
          .style("text-anchor", "middle")
          .text("Earlier Adoption, Less Policies");

        // Quadrant 2
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr(
            "transform",
            "translate(" + ((width - margin.right) / 3) + " ," + (15) + ")"
          )
          .style("text-anchor", "middle")
          .text("Earlier Adoption, More Policies");

         // Quadrant 3
         barsvg
         .append("text")
         .attr("class", "axislabel")
         .attr(
           "transform",
           "translate(" + ((width - margin.right) - 150) + " ," + (xaxisLocation - 70) + ")"
         )
         .style("text-anchor", "middle")
         .text("Later Adoption, Less Policies");

       // Quadrant 4
       barsvg
         .append("text")
         .attr("class", "axislabel")
         .attr(
           "transform",
           "translate(" + ((width - margin.right) - 150) + " ," + (15) + ")"
         )
         .style("text-anchor", "middle")
         .text("Later Adoption, More Policies");

    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  return (
    <div id={props.id}>
      <div
        ref={anchor}
        
        className={props.smalltext ? 'horizontal-graph' : 'graph1'}
        style={{ position: "relative", width: '900px' }}
      ></div>
      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
          
        {[{ name: 'Median Number of Policies', color: chartColorArray[0] }, { name: 'Median Average Lag Time', color: chartColorArray[1] }].map(item => (
          <Checkbox
            key={item.name}
            text={item.name}
            checked={false}
            cursorDefault
            colorWhenFalse
            color={item.color}
            report={props.report}
          />
        ))} 
      </div>
    </div>
  );
}
