export const dashboardNationalData = { // The order in here is important for the order of the side nav!
    Cases: {
        Federal: {
            primary: "Incidents & Cases",
            secondary: "Federal Cases",
            mapFilters: ["Map View", "Year Case Filed", "Location ", "Trafficking Type"],
            chartFilters: ["Year Case Filed", "Location", "Trafficking Type"],
            defaultChartLoSelection: 'State',
            defaultChartLoValue: ["All states"],
            defaultStateValue: ['All states'],
            defaultDistrictValue: [],
            defaultCountyValue: [],
        },
        StatePolicy: {
            primary: "Incidents & Cases",
            secondary: "State Policy",
            mapFilters: ['Policy', { name: 'Year', type: 'slider'}],
            chartFilters: ['Location', 'Year', 'Policy'],
            defaultChartLoSelection: 'State',
            defaultChartLoValue: ["All states"],
            defaultStateValue: ['All states'],
            defaultDistrictValue: [],
            defaultCountyValue: [],
        },
    }
}