// import { countyData as countyJson } from "../Data/countyJson";
// import { txDistrictJson } from "../Data/txDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsTXData = {
  mapZoom: 5,
  reportMapZoom: 4,
  mapBoxCenter: [-99.901, 37.0586],
  mapBoxCenterReport: [-114.77287069367998, 31.0586],
  mapMaxBounds: [[-113.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  mapMaxBoundsReport: [[-114.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  reportMapWidth: '577px',

  'Federal Cases': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },
    'State Policy': {
      mapZoom: mapsNationalData?.mapZoom,
      reportMapZoom: mapsNationalData?.reportMapZoom,
      mapBoxCenter: mapsNationalData?.mapBoxCenter,
      mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
      mapMaxBounds: mapsNationalData?.mapMaxBounds,
      mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
      reportMapWidth: mapsNationalData?.reportMapWidth,
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}